import { render, staticRenderFns } from "./exts.vue?vue&type=template&id=aa0c08cc&scoped=true&"
import script from "./exts.vue?vue&type=script&lang=js&"
export * from "./exts.vue?vue&type=script&lang=js&"
import style0 from "./exts.vue?vue&type=style&index=0&id=aa0c08cc&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa0c08cc",
  null
  
)

export default component.exports