<template>
  <div class="collect_box">
    <div class="main_header">
            <div class="main_header_text">历史记录</div>
            <div class="main_header_icon" @click="closemin"></div>
        </div>
        <div class="h_header">
            <div class="selectKeys">
                <div class="selectKey" :class="key == 1 ? 'selectKey_active' : ' '" @click="selectKey(1)">全部</div>
                <div class="selectKey" :class="key == 2 ? 'selectKey_active' : ' '" @click="selectKey(2)">文件</div>
                <div class="selectKey" :class="key == 3 ? 'selectKey_active' : ' '" @click="selectKey(3)">图片/视频</div>
            </div>
            <div class="search">
                <div style="height:30px; width: 30%; display:flex;">
                    <a-input-search placeholder="搜索" v-model="searchValue" @search="onSearch" ></a-input-search>
                </div>
                <div style="height:30px; width: 50%; display:flex;">
                    <a-range-picker @change="onChange" :locale="locale" />
                </div>
            </div>
            <div class="list" id="list_message" >
                <div class="loadMore" :class="loading? '': 'loadMoreh'" @click="loadMore">{{loading? '加载中...':'点击加载更多'}}</div>
                <!-- <div class="date-line">10-13</div> -->
                <div class="item-from" v-for="(item,index) in messageList" :key="item.id">
                    <div class="item-header">
                        <div class="item-header-text">{{getName(item)}}</div>
                        <div class="item-header-time">{{dateFormat('YYYY-MM-DD HH:mm',item.createTime)}}</div>
                    </div>
                    <div class="item-body">
                        <!-- 文本消息 -->
                        <div class="text-body" v-if="item.messageType == 'txt' && item.messageMsg.indexOf('[') < 0 && getTypes(item) == 'false' ">{{ item.messageMsg }}</div>
                        <!-- 表情消息 -->
                        <div class="text-body" v-if="item.messageType == 'txt' && item.messageMsg.indexOf('[') >= 0 && getTypes(item) == 'false'">
                            <div v-html="getEmimg(item)"></div>
                        </div>
                        <!-- 图片消息 -->
                        <div class="text-body" v-if="item.messageType == 'img'">
                            <previewImage class="image-box" :src="item.localUrl"/>
                            <a class="file_download" :href="item.url" v-if="item.localurl" download>下载文件</a>
                        </div>
                        <!-- 文件消息 -->
                        <div class="text-body file-box" v-if="item.messageType == 'file'" >
                            <div><img class="image-box-file" :src="getimage(item.messageFileName)"></div>
                            <div style="margin-left: 25px;">
                                <div>{{ item.messageFileName }}</div>
                                <div style="color:#999999">{{ fileSizeFormat(item.messageFileLength) }}</div>
                                <a class="file_download" :href="item.localUrl" download>下载文件</a>
                            </div>
                        </div>
                        <!-- 语音消息 -->
                        <div class="text-body"  v-if="item.messageType == 'audio'"  style="display: flex;">
                            <img class="image-box-audio" style="cursor: pointer;" src="@/assets/audio.png" @click="startplayAudio(item,index)" >
                            <div class="audio-text" v-if="item.messageType == 'audio'">{{item.messageLength}}′′</div>
                            <img class="image-box-audio" style="cursor: pointer; margin-left: 120px; width: 18px;" src="@/assets/wenzi.png" @click="redAudio(item,index)">
                        </div>
                        <div class="text-body" v-if="item.messageType == 'audio' && item.redfy == 1" style="padding-bottom: 2px;" >
                            <div>{{item.redtext}}</div>
                            <div style="width: 100%; text-align: center;cursor: pointer;" @click="coloseRed(item)"><img style="width: 20px;"  src="@/assets/upred.png"  ></div>
                        </div>
                        <!-- 视频消息 -->
                        <div class="body-text text-body" v-if="item.messageType == 'video'"  @click="startplayAudio(item,index)" style="display: flex;">
                            <video :src="item.localUrl" style="width: 200px;" controls="controls"></video>
                        </div>
                        <!-- 自定义消息 -->
                        <div class="body-text text-body" v-if="item.messageType == 'custom'">
                            <div  v-if="item.messageCustomExts.type == 'renderProduct'">
                                <!-- <div>{{item.messageCustomExts.actionNo}}</div> -->
                                <div class="custom-body">
                                    <div class="custom-img">
                                        <img :src="item.messageCustomExts.image"/>
                                    </div>
                                    <div class="custom-text">
                                        <div class="custom-name">{{getStringExt(item.messageCustomExts.name)}}</div>
                                    </div>
                                </div>
                                <!-- <div class="custom-action">
                                    <a-button type="default" class="custom-ok" @click="preInfos(item)">{{item.messageCustomExts.actionName}}</a-button>
                                </div> -->
                            </div>
                            <div  v-if="item.messageCustomExts.type == 'designerProduct'">
                                <!-- <div>{{item.messageCustomExts.actionNo}}</div> -->
                                <div class="custom-body">
                                <div class="custom-img">
                                    <img :src="item.messageCustomExts.image"/>
                                </div>
                                <div class="custom-text">
                                    <div class="custom-name">{{getStringExt(item.messageCustomExts.name)}}</div>
                                    <div class="custom-price">¥<span style="font-size:14px;">{{fen2yuan(item.messageCustomExts.price)}}</span></div>
                                </div>
                                </div>
                                <!-- <div class="custom-action">
                                <a-button type="default" class="custom-ok" @click="preInfos(item)">{{item.messageCustomExts.actionName}}</a-button>
                                </div> -->
                            </div>
                            <div  v-if="item.messageCustomExts.type == 'designerOrder'">
                                <div class="custom-body">
                                <div class="custom-img">
                                    <img :src="item.messageCustomExts.image"/>
                                </div>
                                <div class="custom-text">
                                    <div class="custom-name">{{getStringExt(item.messageCustomExts.name)}}</div>
                                    <div class="custom-price">¥<span style="font-size:14px;">{{fen2yuan(item.messageCustomExts.price)}}</span></div>
                                </div>
                                </div>
                                <!-- <div class="custom-action">
                                <a-button type="default" class="custom-ok" @click="preInfos(item)">{{item.messageCustomExts.actionName}}</a-button>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { EaseChatClient, EaseChatSDK } from '@/IM/initwebsdk'
import storage from 'store2'
import { message } from 'ant-design-vue';
import { Modal } from 'ant-design-vue';
import emMap  from '@/utils/em'
import dateFormat from '@/utils/dateFormater'
import { ipcApiRoute ,requestHttpGet,requestHttpPostByOnline} from '@/api/main'
import fileSizeFormat from '@/utils/fileSizeFormat'
import BenzAMRRecorder from 'benz-amr-recorder'
import { MessageUtils } from '@/utils/MessageUtils'
import apiUrl from '@/utils/config'
import KDXF from '@/Kdxf/kdxf'
import previewImage from "@/components/elimage.vue"
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
export default {
    components: {
        previewImage
    },
    props: {
        uid: {
            type: String,
            default: ''
        },
        chatType: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            locale,
            key:1,
            userid:"",
            type:"",
            searchValue:"",
            messageList:[],
            loadList:[],
            msgTypes:[],
            emMap:emMap,
            dateFormat:dateFormat,
            preimgurl:"",
            imgVisible:false,
            multiples:1,
            fileSizeFormat: fileSizeFormat,
            audioPlayStatus:{
                playIndex:-1,
                isPlaying:false
            },
            topvl:false,
            loading:false,
            page:1,
            pageSize:30,
            dateTimeArr:[]
            
        };
    },
    watch: {
        uid: function () {
            this.userid = this.uid
        },
        chatType: function () {
            this.type = this.chatType
        }
    },
    mounted() {
        this.userid = this.uid
        this.type = this.chatType
        this.messageList = []
        this.initdata()
    },
    beforeDestroy() {

    },
    methods: {
        getTypes(v){
        if(v.messageType == "txt"){
            let textmsg = v.messageMsg
            if(textmsg.indexOf("http") == 0){
            if(textmsg.indexOf("Goods_Goods")>=0 || textmsg.indexOf("Supplier_Goods")>=0){
                //设计商品
                //普通商品
                return "goods"
            }else if(textmsg.indexOf("Shop")>=0){
                //店铺
                return "goods"
            }else if(textmsg.indexOf("renderingHall")>=0){
                //效果图
                return "goods"
            }else{
                //网址
                return "http"
            }
            }
        }
        return "false"
        },
        preInfos(e){
            this.topvl = true
        },
        onChange(d,s){
            this.dateTimeArr = []

            this.dateTimeArr = s
            console.log(this.dateTimeArr)
            this.page = 1
            this.messageList = []
            this.initdata()
        },
        //截取字符串长度
        getStringExt(e){
            if(e.length > 24){
                return e.substring(0,24)+'...'
            }
            return e
        },
        fen2yuan(price) {
            return (price / 100.0).toFixed(2)
        },
        getButtonTextTwo(e){
            let res = ""
            switch (e.status) {
                case '请您仔细查阅需求并进行确认':
                res = "确认需求"
                break;
                case '请您确定设计方案':
                res = "去确认"
                break;
                case '请确定申请修改':
                res = "确认申请"
                break;
                case '请您对我们的方案做出评价':
                res = "立即评价"
                break;
                case '点击查看详情':
                res = "查看详情"
                break;
                default:
                res = "查看详情"
            }
            return res
        },
        getimage(item){
            if(item=='' || item == null){
                return
            }
            let res = require("@/assets/pngfile.png")
            switch (item.split(".")[1]) {
                case 'png':
                    res = require("@/assets/pngfile.png")
                break;
                case 'doc':
                    res =  require("@/assets/wfile.png")
                break;
                case 'xlsx':
                    res =  require("@/assets/xfile.png")
                break;
                case 'dwg':
                    res =  require("@/assets/cadfile.png")
                break;
                case 'zip':
                res =  require("@/assets/zfile.png")
                break;
                case 'pptx':
                res =  require("@/assets/pfile.png")
                break;
                default:
                break;
            }
            return res
        },
        coloseRed(item){
            item.redfy = 0
            this.$forceUpdate();
        },
        startplayAudio(item,index){
            const armRec = new BenzAMRRecorder()
            const src = item.localUrl
            this.audioPlayStatus.playIndex = index
            console.log('>>>>>开始播放音频', item.localUrl)
            //初始化音频源并调用播放
            armRec.initWithUrl(src).then(() => {
                if (!this.audioPlayStatus.isPlaying) {
                    armRec.play()
                }
            })
            //播放开始监听
            armRec.onPlay(() => {
                this.audioPlayStatus.isPlaying = true
                this.audioPlayStatus.playIndex = index
            })
            //播放结束监听
            armRec.onStop(() => {
                this.audioPlayStatus.isPlaying = false
                this.audioPlayStatus.playIndex = -1
            })
        },
        redAudio(item,index){
            item.redfy = 1;
            this.$forceUpdate();
            if(item.redtext){
                return
            }
            console.log(item.localUrl)
            let that = this
            KDXF.redfile(item.localUrl,(redtext)=>{
                item.redtext = redtext
                that.$forceUpdate();
            });
        
        },
        preImg(url){
            if (this.$ipc) {
                storage.set("preImages",url)
                this.$ipc.invoke(ipcApiRoute.newWinSize, {name:"preimg",content:window.location.protocol+"//"+window.location.host+"/#/preimg",title:'图片预览'}).then(result => {
                    console.log(result)
                })
            } else {
                this.preimgurl = url
                this.imgVisible = true
            }
        },
        sximg(){
            if (this.multiples <= 1) {
                return;
            }       
            this.multiples -= 0.25;
            },
        pfimg(){
            if (this.multiples >= 2) {
                return;
            }       
            this.multiples += 0.25;
        },
        getEmimg(item){
            if(item.messageMsg.indexOf('[') >=0 ){
                let msgs = item.messageMsg
                //分析出所有表情
                for (const key in this.emMap) {
                if(item.messageMsg.indexOf(key) >= 0){
                    msgs = msgs.replace(key,'<img src="'+require('../assets/faces/' + this.emMap[key])+'"/>')
                }
                }
                return msgs
            }else{ 
                return item.messageMsg
            }
        },
        closemin(){
            this.$emit('closemin')
        },
        getName(item){
            if(storage.get("ImAcount") == item.from){
                return item.designerName
            }else{
                return item.userName
            }
        },
        onSearch(){
            this.messageList = []
            this.page= 1
            this.initdata();
        },
        selectDate(){

        },
        selectKey(e){
            this.key = e
            this.messageList = []
            this.page = 1
            this.initdata()
        },
        loadMore(){
            console.log("点击加载更多")
            this.orderscrollEvent()
        },
        async orderscrollEvent(e){
            var that = this
            if(!that.loading){
                //加载更多
                this.page++
                this.initdata()
            }
        },
        async initdata() {
            console.log("初始化消息",this.userid)
            console.log("初始化消息",this.type)
            this.loading = true
            //分页获取消息
            let obj = {}
            if(this.type == 'groupChat'){
                obj.messageChatType = 'groupChat'
                obj.messageGroupId = this.userid
                if(this.searchValue!=''){
                    obj.messageMsg = this.searchValue
                }
                obj.pageNo = this.page
                obj.pageSize = this.pageSize
            }
            if(this.type == 'singleChat'){
                obj.messageChatType = 'chat'
                obj.messageFrom = this.userid
                obj.messageTo =  storage.get("ImAcount")
                if(this.searchValue!=''){
                    obj.messageMsg = this.searchValue
                }
                obj.pageNo = this.page
                obj.pageSize = this.pageSize
            }
            if(this.key == 1){
                obj.messageType = undefined
            }
            if(this.key == 2){
                obj.messageType = 'file'
            }
            if(this.key == 3){
                obj.messageType = 'iv'
            }
            console.log(this.dateTimeArr)
            if(this.dateTimeArr.length>0 && this.dateTimeArr[0]!=''){
                obj.messageTimestamp = []
                obj.messageTimestamp.push(new Date(this.dateTimeArr[0]+" "+"00:00:00").getTime())
                obj.messageTimestamp.push(new Date(this.dateTimeArr[1]+" "+"23:59:59").getTime())
            }
            let res = await requestHttpPostByOnline(apiUrl+"/admin-api/supplier/message/page-admin-pc-im",obj)
            console.log(res)
            this.loading = false
            res.data.list.forEach(e => {
                e.messageCustomExts = JSON.parse(e.messageCustomExts)
                this.messageList.unshift(e)
            });
            setTimeout(() => {
                let scrollEl = document.getElementById("message")
                scrollEl.scrollTo({ top: scrollEl.scrollHeight + 200, behavior: 'smooth' });
            }, 500);
          
            // if(!this.type){
            //     return
            // }
            // this.MessageList = []
            // if (storage.get('ImAcount') == null) {
            //     console.log("请登录")
            //     if (!this.$ipc) {
            //     this.$router.push('/login')
            //     }
            //     return
            // }
            // if (!this.userid) {
            //     console.log(this.userid)
            //     console.log("无会话消息")
            //     return
            // }
            // this.getFromUserName()
            // this.username = storage.get('UserName')
            // console.log(this.type)
            // this.page = -1
            // this.MessageList = await MessageUtils.getHistoryMessages(this.userid,this.type,this.page)
            // await MessageUtils.autoSaveFile(this.MessageList,this.$ipc)
            // //回复已读消息
            // this.MessageList .forEach(el => {
            //     if(el.from != storage.get("ImAcount") && el.chatType != "groupChat" ){
            //         MessageUtils.sendMessageNoteGoBack(el.from,el.chatType,el.id,this)
            //     }
            // });
            // this.$forceUpdate();
            // setTimeout(() => {
            //     let scrollEl = document.getElementById("message")
            //     scrollEl.scrollTo({ top: scrollEl.scrollHeight + 200, behavior: 'smooth' });
            // }, 500);
            // console.log(this.MessageList)
        },
        async getFromUserName(){
            if(this.type != "groupChat"){
            let userinfo = await EaseChatClient.fetchUserInfoById(this.userid)
            console.log(userinfo)
            this.fromName = userinfo.data[this.userid]['nickname']
            let groupNoteInfo = await requestHttpGet(apiUrl+'/admin-api/supplier/conversation-remark/get-info', {
                conversationId: this.userid, userId: storage.get("ImAcount")
            })
            console.log(groupNoteInfo)
            if(groupNoteInfo.code == 0 && groupNoteInfo.data!=null){
                this.fromName  =  groupNoteInfo.data.name
            }
            //获取装修标识
            // let res = await requestHttpGet('https://www.binliyoujia.com/index.php?ctl=Goods_Imuser&met=is_decoration&typ=json', {
            //   user_id:this.userid
            // })
            // if(res.data.msg == '1'){
            //   this.fromNameZ = true
            // }else{
            //   this.fromNameZ = false
            // }
            this.fromNameZ = false
            }else{
            let groupinfo = await EaseChatClient.getGroupInfo({groupId: this.userid})
            console.log(groupinfo)
            this.fromName = groupinfo.data[0]['name']

            let groupNoteInfo = await requestHttpGet(apiUrl+'/admin-api/supplier/conversation-remark/get-info', {
                conversationId: this.userid, userId: storage.get("ImAcount")
            })
            if(groupNoteInfo.code == 0 && groupNoteInfo.data!=null){
                this.fromName  =  groupNoteInfo.data.name
            }
            }
        },
        
    },

}
</script>
<style lang="less" scoped>
.collect_box {
    padding-top: 0px;
}
.main_header{
    display: flex;
    justify-content: space-between;
}
.main_header_text{
    font-family: PingFangSCMedium-Medium;
    font-size: 14px;
    color: #222222;
}
.main_header_icon{
    width:16px;
    height:16px;
    background-image:url("~@/assets/main-close.png");
    background-size:100%;
    cursor: pointer;
}
.date{
    width:24px;
    height:24px;
    background-image:url("~@/assets/date.png");
    background-size:100%;
    cursor: pointer;
}
.h_header{
    margin-top: 16px;
    padding-left: 10px;
    padding-right: 10px;
}
.selectKeys{
    display:flex;
}
.selectKey{
    margin-right:32px;
    margin-top:5px;
    font-family: PingFangSC-Regular;
    font-size: 12px;
    font-weight: normal;
    line-height: 14px;
    letter-spacing: 0px;
    color: #222222;
    cursor: pointer;
}
.selectKey_active{
    margin-right:32px;
    margin-top:5px;
    font-family: PingFangSC-Regular;
    font-size: 12px;
    font-weight: normal;
    line-height: 14px;
    letter-spacing: 0px;
    color: #ED2616;
    cursor: pointer;
}
.search{
    display:flex;
    justify-content:space-between;
    margin-top:16px;
}

.date-line{
    font-family: PingFangSC-Medium;
    font-size: 10px;
    font-weight: normal;
    line-height: 12px;
    letter-spacing: 0px;
    color: #999999;

}
.list{
    height:350px;
    margin-top:16px;
    text-align:left;
    overflow-y:auto;
}
.list::-webkit-scrollbar{
  background-color: #fff;
  width: 3px;
}
.list::-webkit-scrollbar-thumb {
  border-radius: 20px;
  box-shadow: inset 0 0 2px rgba(0,0,0,0.1);
  background: rgba(0,0,0,0.1);
}
.date-line{
    text-align:center;
}
.item-from{
    margin-top:8px;
}
.item-header{
    display:flex;
}
.item-body{
    margin-top:10px;
}
.item-header-text{
    margin-right:10px;
    font-family: PingFangSC-Regular;
    font-size: 10px;
    font-weight: normal;
    line-height: 12px;
    letter-spacing: 0px;
    color: #666666;
}
.item-header-time{
    margin-right:10px;
    font-family: PingFangSC-Regular;
    font-size: 10px;
    font-weight: normal;
    line-height: 12px;
    letter-spacing: 0px;
    color: #666666;
}
.body-text{
    font-family: PingFangSC-Regular;
    font-size: 12px;
    font-weight: normal;
    line-height: 18px;
    letter-spacing: 0px;
    color: #444444;
}
.image-box {
  width: 100px;
}

.imgheader{
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  position: relative;
}
/deep/ .ant-modal-body{
  padding: 16px;
}
.imgbox{
  display: flex;
  justify-content: center;
  height: 640px;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.imgbox img{
  max-width: 80%;
  max-height: 640px;
  position: absolute;
  z-index: 10;
  user-select: none;
}
.imgaction{
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
  margin: auto;
}
.imgtitleaction{
  position: absolute;
  right: 16px;
  top: 0px;
}
.file-box {
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
}
.image-box-file {
  width: 54px;
}
.image-box-audio{
  width: 14px;
  cursor: pointer;
}
.audio-text{
  font-family: PingFangSCRegular-Regular;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0em;
  color: #999999;
  margin-left: 8px;
}
.text-body {
  max-width: 40%;
  opacity: 1;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: 0px;
  color: #222222;
  text-align: left;
  border-radius: 0px 10px 10px 10px;
  padding: 10px;
  background: #EEEEEE;
  margin-top: 6px;
}
*, *::before, *::after {
    box-sizing: initial;
}
.custom-body{
  display: flex;
  justify-content: flex-start; 
}
.custom-img{
  width: 60px;
  height: 60px;
}
.custom-img img{
  width: 60px;
  height: 60px;
  border-radius: 5px 0px 0px 5px;
}
.custom-text{
  height: 60px;
  width: 180px;
  margin-left:8px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}
.custom-name{
  width: 100px; /* 设置容器的宽度 */
  white-space: nowrap; /* 禁止文本换行 */
  overflow: hidden; /* 隐藏超出部分的内容 */
  text-overflow: ellipsis; /* 当文本溢出时显示省略号 */
}
.custom-action{
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
.custom-cel{
  border-radius: 20px;
  height: 29px;
  width: 100px;
  box-sizing: border-box;
}
.custom-ok{
  border-radius: 20px;
  height: 29px;
  font-size:12px;
  opacity: 1;
  background: linear-gradient(90deg, #FF6044 0%, #ED2616 100%);
  box-shadow: 0px 8px 8px 0px rgba(237, 38, 22, 0.1);
  border: none;
  color: #fff;
}
.custom-ok-cleal{
  border-radius: 20px;
  font-size:12px;
  height: 29px;
  opacity: 1;
  background: none;
  background-color:none;
  border:1px solid #222;
}
.custom-ok-cleal:hover{
  border-radius: 20px;
  font-size:12px;
  height: 29px;
  opacity: 1;
  background: none;
  background-color:none;
  border:1px solid #ED2616;
  color: #ED2616 !important;
}
.custom-ok-cleal:active{
  border-radius: 20px;
  font-size:12px;
  height: 29px;
  opacity: 1;
  background: none;
  background-color:none;
  border:1px solid #ED2616;
  color: #ED2616;
}
.custom-ok-cleal:focus{
  border-radius: 20px;
  font-size:12px;
  height: 29px;
  opacity: 1;
  background: none;
  background-color:none;
  border:1px solid #ED2616;
  color: #ED2616;
}
.loadMore{
  font-size: 12px;
  color: #999999;
  margin-top: 10px;
  cursor: pointer;
  text-align: center;
}
.loadMoreh{
  color: blue;
  text-align: center;
}
.image-box-audio{
  width: 14px;
  cursor: pointer;
}
.audio-text{
  font-family: PingFangSCRegular-Regular;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0em;
  color: #999999;
  margin-left: 8px;
}
.custom-price{
  color:#ED2616;
  font-weight:600;
  margin-top:2px;
}
</style>
