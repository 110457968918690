<template>
    <div class="collect_box">
        <div class="selectKeys">
            <div v-if="type !== 'groupChat'" class="selectKey" :class="key == 1 ? 'selectKey_active' : ' '" @click="selectKey(1)">{{userType == 1 ? '访客信息' : '个人信息'}}</div>
            <div  class="selectKey" :class="key == 2 ? 'selectKey_active' : ' '" @click="selectKey(2)" v-if="userType == 1 && type !== 'groupChat'">用户订单</div>
            <div  class="selectKey" :class="key == 3 ? 'selectKey_active' : ' '" @click="selectKey(3)" v-if="userType == 1 && type !== 'groupChat'">用户足迹</div>
            <div class="selectKey" :class="key == 4 ? 'selectKey_active' : ' '" @click="selectKey(4)">常用语</div>
        </div>
        <div class="user-line"></div>
        <div class="body-content" v-if="key == 1">
            <div class="b-item">名字: {{userinfo.nickname}}</div>
            <div class="b-item">年龄: {{userinfo.age>0?userinfo.age:'未知'}}</div>
            <div class="b-item">性别: {{userinfo.sex == 0?'男':'女'}}</div>
            <div class="b-item">地区: {{userinfo.address == null?'未知':userinfo.address}}</div>
            <div class="b-item">平台: {{'APP'}}</div>
            <div class="b-item">手机号: {{userinfo.mobile}}</div>
            <div class="b-item">跟进人: {{userinfo.sysName}}</div>
            <div class="b-item">相关项目组</div>
            <div class="b-item-plant">
                <div class="group-list" v-for="item in userGroupList" :key="item.id">
                    <div style="display:flex;justify-content:flex-start;    align-items: center;">
                        <img style="width:30px; height: 30px;" src="../assets/groupTheme.png"></img>
                        <div style="margin-left:5px;">{{item.teamName}}</div>
                    </div>
                    <div style="text-align: right;margin-top: 8px;"><a-button type="primary" class="order-button" @click="goToGroup(item)">进入</a-button></div>
                </div>
            </div>
        </div>
        <div class="body-order" v-if="key == 2">
            <div class="z-tabs">
                <div class="z-tab" :class="orderType == 1 ?'z-tab-active' :''" @click="setOstill(1)">设计订单</div>
                <div class="z-tab" :class="orderType == 0 ?'z-tab-active' :''" @click="setOstill(0)">商品订单</div>
            </div>
            <div class="search-item" style="margin-top: 10px;"><a-input-search placeholder="搜索订单号" v-model="orderkey" @search="selectKey(2)"></a-input-search></div>
            <div class="card-list" @scroll="orderscrollEvent">
                <div class="b-card" v-for="item in orderList" :key="item.id">
                    <div class="order-head">
                        <div class="order-id">订单编号：{{item.no}}</div>
                        <div cl ass="order-copy" @click="copyhaln(item.no)">复制</div>
                    </div>
                    <div class="order-line"></div>
                    <div class="order-body">
                        <img class="order-body-header" :src="item.items[0].picUrl">
                        <div class="order-body-text">
                            <div class="p-b-t">{{item.items[0].spuName}}</div>
                            <div class="p-b-d">
                                <div class="p-b-num">×{{item.items[0].count}}</div>
                                <div class="p-b-amount">实付款：<span>¥{{fen2yuan(item.payPrice)}}</span></div>
                            </div>
                        </div>
                    </div>
                    <div class="order-line"></div>
                    <div class="order-dec">
                        <span>创建时间</span><span>{{dateFormat("YYYY-MM-DD hh:mm:ss",item.createTime)}}</span>
                    </div>
                    <div class="order-dec">
                        <span>支付方式</span><span>{{item.payChannelCode!=''?payChannelCode[item.payChannelCode]:"未支付"}}</span>
                    </div>
                    <div class="order-dec">
                        <span>订单状态</span><span>{{item.refundStatus?refundStatusMap[item.refundStatus]:orderStatusMap[item.status]}}</span>
                    </div>
                    <div style="text-align: right;margin-top: 8px;"><a-button type="primary" class="order-button" @click="onBackZdy(item)">发送</a-button></div>
                </div>
            </div>
        </div>
        <div class="body-order" v-if="key == 3">
            <div class="z-tabs">
                <div class="z-tab" :class="still==1 ?'z-tab-active' :''" @click="setstill(1)">效果图</div>
                <div class="z-tab" :class="still==0 ?'z-tab-active' :''" @click="setstill(0)">设计商品</div>
            </div>
            <div v-if="still == 1">
                <div class="z-tabs-list" @scroll="footscrollEvent">
                    <div class="z-tabs-item"  v-for="item in openHostList" :key="item.id">
                        <div class="z-tabs-img">
                            <img :src="getRenderImage(item.footprintRender)">
                            <a-checkbox class="mcheck" v-model="item.check"></a-checkbox>
                        </div>
                        <div class="z-tabs-text">{{item.footprintName}}</div>
                    </div>
                </div>
      
                <div class="z-action">
                    <div> <a-checkbox  v-model="checkall" @change="checkedALl">全选</a-checkbox></div>
                    <a-button type="primary" class="order-button" @click="sendFoot">发送</a-button>
                </div>
            </div>
            <div class="goods-list" v-if="still == 0" @scroll="footscrollEvent">
                <div class="goods-item" v-for="item in openHostList" :key="item.id">
                    <img class="order-body-header" :src="item.footprintDesignerProduct.picUrl">
                    <div class="order-body-text">
                        <div class="p-b-t">{{item.footprintDesignerProduct.name}}</div>
                        <div class="p-b-d">
                            <div class="p-b-amount" style="margin-top: 2px;"><span>¥ {{fen2yuan(item.footprintDesignerProduct.price)}}</span></div>
                            <a-button type="primary" class="order-button" @click="sendFootG(item)">发送</a-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="body-order" style="background-color: #fff;" v-if="key == 4">
            <div class="search-item"><a-input-search placeholder="搜索" v-model="searchValue" @search="onSearch"></a-input-search></div>
            <div class="c-title">
                <div class="c-t-text">快捷用语</div>
                <img class="c-t-icon" src="@/assets/fcilck.png">
            </div>
            <div class="c-title-b">
                <div class="c-t-b-list" v-for="(item,index) in cyList" :key="item.id" @click="backCy(item)">{{(index+1)}} {{item.text}}</div>
            </div>
            <div class="order-line"></div>
            <div class="c-panel">
                <a-collapse v-model="activeKey" @change="changeClassByIndex">
                    <template #expandIcon="{ isActive }">
                        <img  src="@/assets/filelefticon.png" :class="isActive ? 'left-icon-r' : 'left-icon'" />
                    </template>
                    <a-collapse-panel :key="String((index+1))" v-for="(item,index) in buyerCList" >
                        <template #header>
                            <div class="c-file">
                                <img class="c-t-icon-file" src="@/assets/fileicon.png">
                                <div class="c-title-text">{{item.groupName}}</div>
                                <a-popover v-model="item.pvisible" placement="bottomRight" trigger="click" arrow-point-at-center>
                                    <template #content>
                                        <div class="al" @click="actionTag(item,1)">编辑</div>
                                        <div class="al" @click="actionTag(item,2)">新增</div>
                                        <div class="al" @click="actionTag(item,3)">删除</div>
                                    </template>
                                    <div class="c-t-icon-right-plant" :class="item.pvisible ? 'c-t-icon-right-plant-active' : ''" @click.stop = "mianban(item)" >
                                        <img v-if="item.pvisible" class="c-t-icon-right" src="@/assets/fcilck1.png" style="margin-top: 3px;" />
                                        <img v-if="!item.pvisible" class="c-t-icon-right" src="@/assets/fcilck.png" style="margin-top: 3px;" />
                                    </div>
                                </a-popover>
                            </div>
                        </template>
                        <div class="c-list-yu" >
                            <div v-for="(ie,i) in item.buyerList"  :key="i+'bylist'">
                                <div class="c-file">
                                    <div  class="c-t-b-list" @click="backCy(ie)">{{ie.text}}</div>
                                    <a-popover v-model="ie.pvisible" placement="bottomRight" trigger="click" arrow-point-at-center style="margin-top: 16px;">
                                        <template #content>
                                            <div class="al" @click="actionTagX(ie,1)">编辑</div>
                                            <div class="al" @click="actionTagX(ie,3)">删除</div>
                                        </template>
                                        <div class="c-t-icon-right-plant" :class="ie.pvisible ? 'c-t-icon-right-plant-active' : ''" @click.stop = "mianban(ie)" >
                                            <img v-if="ie.pvisible" class="c-t-icon-right" src="@/assets/fcilck1.png" style="margin-top: 3px;" />
                                            <img v-if="!ie.pvisible" class="c-t-icon-right" src="@/assets/fcilck.png" style="margin-top: 3px;" />
                                        </div>
                                    </a-popover>
                                </div>
                            </div>
                        </div>
                    </a-collapse-panel>
                </a-collapse>
            </div>
            <a-modal title="新建常用语文件夹" v-model="addbuyerClass" cancelText="取消" okText="保存" width="400px" @ok="savebuyerClass">
                <a-input v-model="addbuyerClassName" placeholder="请输入文件夹名称"></a-input>
            </a-modal>
            <a-modal title="新建常用语" v-model="addbuyer" cancelText="取消" okText="保存" width="400px" @ok="savebuyer">
                <a-input v-model="addbuyerName" placeholder="请输入常用语"></a-input>
            </a-modal>
            <a-modal title="编辑常用语文件夹" v-model="editbuyerClass" cancelText="取消" okText="保存" width="400px" @ok="editbuyersave">
                <a-input v-model="editbuyerClassName" placeholder="请输入文件夹名称"></a-input>
            </a-modal>
            <a-modal title="编辑常用语" v-model="editbuyerInfo" cancelText="取消" okText="保存" width="400px" @ok="editbuyerInfosave">
                <a-input v-model="editbuyerInfoName" placeholder="请输入常用语"></a-input>
            </a-modal>
            
            
            <a-button class="newClass" @click="addbuyerHanle" >新建文件夹</a-button>
        </div>
    </div>
</template>
<script>
import { message } from 'ant-design-vue';
import { EaseChatClient, EaseChatSDK } from '@/IM/initwebsdk'
import storage from 'store2'
import { Modal } from 'ant-design-vue';
import dateFormat from '@/utils/dateFormater'
import { ipcApiRoute , requestHttpPostByOnline , requestHttpGet} from '@/api/main'
import apiUrl from '@/utils/config'
import {orderStatusMap,payChannelCode,refundStatusMap} from '@/utils/orderStatusMap'
export default {
    props: {
        uid: {
            type: String,
            default: ''
        },
        chatType: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            orderStatusMap:orderStatusMap,
            refundStatusMap:refundStatusMap,
            payChannelCode:payChannelCode,
            key:1,
            checklist:[],
            allList:0,
            still:1,
            activeKey:"",
            pvisible:false,
            userid:0,
            type:"",
            userType:0,
            searchValue:"",
            userinfo:{},
            cyList:[],
            orderList:[],
            orderfirstRow:1,
            openHostList:[],
            orderkey:"",
            curpage:1,
            checkall:false,
            orderType:1,
            buyerCList:[],
            exbuyer:{},
            editbuyerClass:false,
            addbuyer:false,
            addbuyerClass:false,
            editbuyerClassName:"",
            addbuyerClassName:"",
            addbuyerName:"",
            exbuyerInfo:{},
            editbuyerInfo:false,
            editbuyerInfoName:"",
            userGroupList:[]
        };
    },
    watch: {
        uid: function () {
            this.userid = this.uid.substring(0, this.uid .length - 1);
            this.initdata()
        },
        chatType: function () {
            this.type = this.chatType
        }
    },
    mounted() {
        this.userid = this.uid.substring(0, this.uid .length - 1);
        this.type = this.chatType
        console.log(this.type)
        if(this.type == 'groupChat'){
            this.key = 4
        }
        console.log(this.orderStatusMap)
        this.initdata()
    },
    beforeDestroy() {

    },
    methods: {
        goToGroup(item){
            //进入项目组
            this.$emit('gotoGroup',item.teamId)
        },
        async getUsetGroupList(){
            this.userGroupList = []
            //获取用户加入的项目组
            let obj = {
                userId: this.userid.split("im")[1]
            }
            console.log(obj)
            let orders = await requestHttpGet(apiUrl+'/admin-api/trade/order/user-shop-page-by-designer',obj)
            orders.data.list.forEach(e => {
                if(e.teamId!="" && e.teamId!=null){
                    this.userGroupList.push(e)
                }
            });
            console.log(this.userGroupList)
        },
        dateFormat(f,t){
            return dateFormat(f,t)
        },
        //获取常用语分组
        async getBuyerList(){
            let buyer = await requestHttpGet(apiUrl+'/admin-api/supplier/user-phrases-group/list')
            buyer.data.forEach(e => {
                e.pvisible = false;
                e.buyerList = [{pvisible:false}]
            });
            this.buyerCList = buyer.data
            console.log(this.buyerCList)
        },
        async changeClassByIndex(e){
          
            for (let index = 0; index < e.length; index++) {

                let obj = {
                    groupId:this.buyerCList[e[index]-1].id
                }
                if(this.searchValue!=''){
                    obj.text = this.searchValue
                }
                let res = await requestHttpGet(apiUrl+'/admin-api/supplier/user-phrases/list',obj)
                res.data.forEach(e => {
                    e.pvisible = false;
                });
                this.buyerCList[e[index]-1]['buyerList'] = res.data
                console.log(this.buyerCList)
            }
        },
        async changeClass(e){
            console.log(e)
            for (let index = 0; index < e.length; index++) {
                let obj = {
                    groupId:e.id
                }
                if(this.searchValue!=''){
                    obj.text = this.searchValue
                }
                let res = await requestHttpGet(apiUrl+'/admin-api/supplier/user-phrases/list',obj)
                res.data.forEach(e => {
                    e.pvisible = false;
                });
                this.buyerCList.forEach(b => {
                    if(b.id == e.id){
                        b['buyerList'] = res.data
                    }
                });
                console.log(this.buyerCList)
            }
        },
        //订单滚动到底部触发刷新事件
        orderscrollEvent(e){
            if(e.srcElement.scrollTop + e.srcElement.clientHeight == e.srcElement.scrollHeight ) 
            {
                this.orderfirstRow++
                this.getOrder()
            }
        },
        //足迹滚动到底部触发刷新事件
        footscrollEvent(e){
            if(e.srcElement.scrollTop + e.srcElement.clientHeight == e.srcElement.scrollHeight ) 
            {
                this.curpage++
                this. this.getOpenHost()
            }
        },
        onBackZdy(e){
            console.log(e)
            this.$emit('onBackZdy',e,'designerOrder')
        },
        backCy(e){
            this.$emit('backCy',e)
        },
        sendFoot(){
            this.checklist = []
            this.openHostList.forEach(e => {
                if(e.check){
                    this.checklist.push(e)
                }
            });
            this.$emit('onBackZj',this.checklist,'renderProduct')
        },
        sendFootG(e){
            this.checklist = []
            this.checklist.push(e)
            this.$emit('onBackZj',this.checklist,'designerProduct')
        },
        checkedALl(){
            this.openHostList.forEach(e => {
                e.check = this.checkall
            });
        },
        initdata(){
            //判断用户类型
            if(storage.get("shopType") == 0 ){
                //普通用户
                this.userType = 0
            }else{
                //商家或设计师用户
                this.userType = 1
            }
            switch (this.key) {
                case 1:
                    //获取信息
                    this.getUserInfo()
                    this.getUsetGroupList()
                    break;
                case 2:
                    //获取订单
                    this.orderfirstRow = 1
                    this.orderList = []
                    this.getOrder()
                    break;
                case 3:
                    //获取足迹
                    this.curpage = 1
                    this.openHostList = []
                    this.checklist = []
                    this.getOpenHost()
                    break;
                case 4:
                    //获取常用语
                    this.getCy()
                    break;
                default:
                    break;
            }
        },
        /**
         * 将分转成元
         *
         * @param price 分，例如说 100 分
         * @returns {string} 元，例如说 1.00 元
         */
        fen2yuan(price) {
            return (price / 100.0).toFixed(2)
        },
        async getCy(){
            this.getBuyerList();
            let obj = {}
            if(this.searchValue!=''){
                obj.text = this.searchValue
            }
            let cyList = await requestHttpGet(apiUrl+'/admin-api/supplier/phrases/list',obj)
            this.cyList = cyList.data
        }, 
        async getUserInfo(){
            if(this.userType == 0){
                //调用获取个人信息接口
                let userInfo = await requestHttpGet('https://www.binliyoujia.com//index.php?ctl=Index&met=wxappcheckApp&typ=json', {
                    ks:storage.get('ks'),
                    us:storage.get('us')
                })
                this.userinfo = userInfo.data.data.data
                this.serviceUserId = this.userinfo.user_id
            }else{
                let userInfo = await requestHttpGet(apiUrl+'/admin-api/supplier/user/user-member-info', {
                    id:this.userid.split("im")[1]
                })
                console.log(userInfo)
                this.userinfo = userInfo.data
                this.serviceUserId = this.userinfo.user_id
                //获取访客信息接口
            }
        },
        async getOrder(){
            if(this.userType == 0){
                //调用获取个人订单
                let obj = {
                    k: storage.get('ks'),
					u: storage.get('us'),
                    sxtypes:this.orderType,
                }
                if(this.orderkey != ""){
                    obj.orderkey = this.orderkey
                }
                let orderList = await requestHttpGet('https://www.binliyoujia.com//index.php?ctl=Buyer_Order&met=physical&typ=json&firstRow=' + this.orderfirstRow, obj)
                orderList.data.items.forEach(e => {
                    this.orderList.push(e)
                });
            }else{
                console.log(this.orderType)
                if(this.orderType == 0){
                    //普通订单
                    this.orderList = []
                    return
                }
                //调用获取访客订单
                let obj = {
                    userId: this.userid.split("im")[1],
                    pageNo:this.orderfirstRow
                }
                if(this.orderkey != ""){
                    obj.no = this.orderkey
                }
                console.log(obj)
                let orders = await requestHttpGet(apiUrl+'/admin-api/trade/order/user-shop-page',obj)
                orders.data.list.forEach(e => {
                    this.orderList.push(e)
                });
            }
        },
        async getOpenHost(){
            if(this.userType == 0){
                //调用获取个人足迹
                let obj = {
                    sxtypes:this.still,
                    k: storage.get('ks'),
					u: storage.get('us'),
                    curpage:this.curpage
                }
                let openHostList = await requestHttpGet('https://www.binliyoujia.com//index.php?ctl=Buyer_Favorites&met=historyFootPrint&typ=json', obj)
                Object.keys(openHostList.data.info).forEach(item => {
                    openHostList.data.info[item].list.forEach(e => {
                        this.openHostList.push(e)
                    });
                })
            }else{
                let obj = {
                    userId: this.userid.split("im")[1],
                    pageNo:this.curpage
                }
                if(this.still == 0){
                    //设计商品
                    obj.type = 4  
                }
                if(this.still == 1){
                    //效果图
                    obj.type = 3
                }
                let orders = await requestHttpGet(apiUrl+'/admin-api/member/footprint/page',obj)
                orders.data.list.forEach(e => {
                    this.openHostList.push(e)
                });
            }
        },
        async actionTagX(e,type){
            if(type == 1){
                //编辑
                this.editbuyerInfo = true;
                this.editbuyerInfoName = e.text;
                this.exbuyerInfo = e;
            }
            if(type == 3){
                //删除
                let that = this
                Modal.confirm({
                    title: () => '您确定要删除常用语？',
                    cancelText:"取消",
                    okText:"确定",
                    okType: 'danger',
                    async onOk () {
                        let obj = {
                            id:e.id,
                        }
                        let res = await requestHttpGet(apiUrl+'/admin-api/supplier/user-phrases/delete', obj)
                        if(res.code == 0){
                            message.info('删除成功');
                        }
                        that.addbuyerClass = false;
                        that.changeClass([e.groupId]);
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            }
        },
        async actionTag(e,type){
            e.pvisible = false
            if(type == 1){
                //编辑
                this.editbuyerClass = true;
                this.editbuyerClassName = e.groupName;
                this.exbuyer = e;
            }
            if(type == 2){
                //新增
                this.addbuyer = true;
                this.exbuyer = e;
            }
            if(type == 3){
                //删除
                let that = this
                Modal.confirm({
                    title: () => '您确定要删除分类？',
                    cancelText:"取消",
                    okText:"确定",
                    okType: 'danger',
                    async onOk () {
                        let obj = {id:e.id}
                        let res = await requestHttpGet(apiUrl+'/admin-api/supplier/user-phrases-group/delete', obj)
                        if(res.code == 0){
                            message.info('删除成功');
                        }
                        that.addbuyerClass = false;
                        that.getCy();
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });

                
            }
        },
        addbuyerHanle(){
            this.addbuyerClass = true;
        },
        //新建常用语
        async savebuyer(){
            let obj = {
                text:this.addbuyerName,
                groupId:this.exbuyer.id
            }
            let res = await requestHttpPostByOnline(apiUrl+'/admin-api/supplier/user-phrases/create', obj)
            if(res.code == 0){
                message.info('添加成功');
            }
            this.addbuyer = false;
            this.changeClass([this.exbuyer.id]);
        },
        // 新增常用语分组
        async savebuyerClass(){
            let obj = {
                groupName:this.addbuyerClassName
            }
            let res = await requestHttpPostByOnline(apiUrl+'/admin-api/supplier/user-phrases-group/create', obj)
            if(res.code == 0){
                message.info('添加成功');
            }
            this.addbuyerClass = false;
            this.getCy();
        },
        //修改常用语
        async editbuyerInfosave(){
            let obj = {
                id:this.exbuyerInfo.id,
                text:this.editbuyerInfoName
            }
            let res = await requestHttpPostByOnline(apiUrl+'/admin-api/supplier/user-phrases/update', obj)
            if(res.status == 200){
                message.info('修改成功');
            }
            this.editbuyerInfo = false;
            this.changeClass([this.exbuyerInfo.groupId]);
        },
        //更新常用语分组名称
        async editbuyersave(){
            let obj = {
                id:this.exbuyer.id,
                groupName:this.editbuyerClassName
            }
            let res = await requestHttpPostByOnline(apiUrl+'/admin-api/supplier/user-phrases-group/update', obj)
            if(res.code == 0){
                message.info('修改成功');
            }
            this.editbuyerClass = false;
            this.getCy();
        },
        onSearch(){
            this.getCy()
        },
        mianban(item){
            console.log("面板")
            item.pvisible = !item.pvisible
        },  
        setstill(e){
            this.still = e
            this.selectKey(3)
        },
        setOstill(e){
            this.orderType = e
            this.selectKey(2)
        },
        copyhaln(context){
            let oInput = document.createElement('input');
            // 将想要复制的值
            oInput.value = context;
            // 页面底部追加输入框
            document.body.appendChild(oInput);
            // 选中输入框
            oInput.select();
            // 执行浏览器复制命令
            document.execCommand('Copy');
            // 弹出复制成功信息
            oInput.remove();
            message.info("复制成功!");
        },
        selectKey(e){
            this.key = e
            this.initdata()
        },
        getRenderImage(item){
           return  item.coverPicUrls.split(",")[0]
        }
    },

}
</script>
<style lang="less" scoped>
.collect_box {
    position:relative;
}
.selectKeys{
    padding:16px;
    padding-bottom:0px;
    display:flex;
    justify-content:space-between;
}
.selectKey{
    height:31px;
    margin-top:5px;
    font-family: PingFangSC-Regular;
    font-size: 12px;
    font-weight: normal;
    line-height: 14px;
    letter-spacing: 0px;
    color: #222222;
    cursor: pointer;
    position:relative;
    z-index:2;
}
.selectKey_active{
    height:31px;
    margin-top:5px;
    font-family: PingFangSC-Regular;
    font-size: 12px;
    font-weight: normal;
    line-height: 14px;
    letter-spacing: 0px;
    color: #ED2616;
    border-bottom:1px solid #ED2616;
    cursor: pointer;
}
.user-line{
    height: 1px;
    opacity: 1;
    background: #EEEEEE;
    margin-top:16px;
    position:absolute;
    width:260px;
    left:0px;
    top:35px;
    z-index:1;
}
.body-content{
    background-color:#fff;
    height:480px;
    padding:16px;
    padding-top:0px;
}
.body-order{
    background-color:#FAFAFA;
    height:510px;
    padding:16px;
    padding-top:16px;
    display:block;
    position: relative;
}
.b-item{
    font-family: PingFang SC;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0em;
    color: #222222;
    text-align:left;
    margin-top:16px;
}
.search-item{
    width:228px;
}
.card-list{
    height:410px;

    overflow-y:auto;
}
.card-list::-webkit-scrollbar{
    display:none;
}
.b-card{
    width: 228px;
    height: 230px;
    border-radius: 10px;
    opacity: 1;
    background: #FFFFFF;
    margin-top:16px;
    padding:8px 16px;
}
.order-head{
    display:flex;
    justify-content:space-between;
}
.order-id{
    width: 166px;
    font-family: PingFang SC;
    font-size: 11px;
    font-weight: normal;
    letter-spacing: 0em;
    color: #222222;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.order-copy{
    font-family: PingFang SC;
    font-size: 9px;
    font-weight: normal;
    letter-spacing: 0em;
    color: #ED2616;
    cursor: pointer;
}
.order-line{
    height: 1px;
    opacity: 1;
    background: #EEEEEE;
    margin-top:10px;
}
.order-body{
    display:flex;
    justify-content: flex-start;
    margin-top: 10px;
}
.order-body-header{
    width: 55px;
    height: 55px;
    border-radius: 4px;
    opacity: 1;
    margin-right: 8px;
}
.order-body-text{
    width: 180px;
}
.p-b-t{
    font-family: PingFang SC;
    font-size: 10px;
    font-weight: normal;
    letter-spacing: 0em;
    color: #222222;
    text-align: left;
    height: 30px;
}
.p-b-d{
    display:flex;
    justify-content: space-between;
}
.p-b-num{
    font-family: PingFang SC;
    font-size: 10px;
    font-weight: normal;
    letter-spacing: 0em;
    color: #222222;
}
.p-b-amount{
    font-family: PingFang SC;
    font-size: 10px;
    font-weight: normal;
    letter-spacing: 0em;
    color: #222222;
}
.p-b-amount span{
    color: #ED2616;
}
.order-dec{
    font-family: PingFang SC;
    font-size: 10px;
    font-weight: normal;
    letter-spacing: 0em;
    color: #222222;
    display:flex;
    justify-content: space-between;
    margin-top: 8px;
}
.order-button{
    font-family: PingFang SC;
    font-size: 10px;
    font-weight: normal;
    line-height: 17px;
    letter-spacing: 0em;
    color: #FFFFFF;
    width: 52px;
    height: 23px;
    border-radius: 60px;
    opacity: 1;
    background: #ED2616;
    padding: 0px;
}
.z-tabs{
    display: flex;
    justify-content: flex-start;
}
.z-tab{
    border-radius: 60px;
    opacity: 1;
    background: rgba(136, 136, 136, 0.1);
    font-family: PingFang SC;
    font-size: 11px;
    font-weight: normal;
    line-height: 17px;
    letter-spacing: 0em;
    color: #222222;
    padding: 4px 10px;
    margin-right: 10px;
    cursor: pointer;
}
.z-tab-active{
    border-radius: 60px;
    opacity: 1;
    background: rgba(237, 38, 22, 0.1);
    font-family: PingFang SC;
    font-size: 11px;
    font-weight: normal;
    line-height: 17px;
    letter-spacing: 0em;
    color: #ED2616;
    padding: 4px 10px;
    margin-right: 10px;
    cursor: pointer;
}
.z-tabs-list{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 400px;
    overflow-y: auto;
    margin-top: 10px;
}
.z-tabs-list::-webkit-scrollbar{
    display: none;
}
.z-tabs-text{
    font-family: PingFang SC;
    font-size: 11px;
    font-weight: normal;
    letter-spacing: 0em;
    color: #222222;
    margin-top: 6px;
    text-align: left;
    width: 110px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.z-tabs-item{
    height: 142px;
}
.z-tabs-img{
    position: relative;
    width: 110px;
    height: 110px;
    margin-top: 10px;
}
.z-tabs-img img{
    border-radius: 4px;
    width: 110px;
    height: 110px;
}
.mcheck{
    position: absolute;
    top: 6px;
    right: 6px;
}
/deep/ .ant-checkbox-inner{
    border-radius: 50%;
}
/deep/ .ant-checkbox-checked::after{
    border-radius: 50%;
}
.z-action{
    display: flex;
    justify-content: space-between;
    margin-top: 0px;
    height: 51px;
    background: #fff;
    box-shadow: inset 0px 1px 0px 0px #EEEEEE;
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 260px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 14px;
}
.goods-list{
    height: 450px;
    overflow-y: auto;
    margin-top: 10px;
}
.goods-list::-webkit-scrollbar{
    display: none;
}
.goods-item{
    display:flex;
    justify-content: flex-start;
    margin-top: 10px;
    border-radius: 4px;
    opacity: 1;
    background: #F7F7F7;
    padding: 8px;
}
.c-title{
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    height: 33px;
    opacity: 1;
    background: #FAFAFA;
    padding-top: 8px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 8px;
}
.c-t-text{
    font-family: PingFang SC;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0px;
    color: #222222;
}
.c-t-icon{
    height: 9px;
    width: 2px;
    margin-top: 3px;
}
.c-t-icon-right-plant{
    width: 16px;
    height: 15px;
    top: 0px;
    position: absolute;
    right: 0px;
    display: flex;
    margin-top: 4px;
}
.c-t-icon-right-plant-active{
    width: 16px;
    height: 15px;
    top: 0px;
    position: absolute;
    right: 0px;
    border-radius: 2px;
    opacity: 1;
    background: rgba(237, 38, 22, 0.08);
    display: flex;
    margin-top: 4px;
}

.c-t-icon-right{
    height: 9px;
    width: 2px;
    margin-left: 7px;
}
.c-t-icon-file{
    width: 11px;
    height: 11px;
    margin-top: 6px;
}
.c-title-b{
    height: 138px;
    overflow-y: auto;
    padding-left: 9px;
    padding-right: 9px;
    margin-bottom: 20px;
}
.c-t-b-list{
    font-family: PingFang SC;
    font-size: 11px;
    font-weight: normal;
    letter-spacing: 0px;
    color: #444444;
    text-align: left;
    margin-top: 16px;
}
.c-title-b::-webkit-scrollbar{
  width: 3px;
}
.c-title-b::-webkit-scrollbar-thumb {
  border-radius: 20px;
  box-shadow: inset 0 0 2px rgba(0,0,0,0.1);
  background: rgba(0,0,0,0.1);
}
.c-file{
    text-align: left;
    display: flex;
    position: relative;
}
.c-title-text{
    font-family: PingFang SC;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0px;
    color: #222222;
    margin-left: 10px;
}
.left-icon{
    width: 8px;
    height: 6px;
    transform: rotate(270deg) !important;
}
.left-icon-r{
    width: 8px;
    height: 6px;
    transform: rotate(0deg) !important;
}
/deep/ .ant-collapse > .ant-collapse-item > .ant-collapse-header{
    padding-left: 25px;
    background: #FAFAFA;
    padding-right: 4px;
    padding-top: 8px;
    padding-bottom: 8px;
}
/deep/ .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow{
    left: 10px;
    top: 17px;
}
.c-list-yu{
    padding-left: 30px;
}
.al{
    font-family: PingFang SC;
    font-size: 11px;
    font-weight: normal;
    letter-spacing: 0em;
    color: #222222;
    cursor: pointer;
}
.al:hover{
    font-family: PingFang SC;
    font-size: 11px;
    font-weight: normal;
    letter-spacing: 0em;
    color: #ED2616;
    cursor: pointer;
}
/deep/ .ant-popover-inner-content{
    padding: 8px 10px;
}
.c-panel{
    margin-top: 5px;
    height: 200px;
    overflow-y: auto;
}
.c-panel::-webkit-scrollbar{
  display: none;
}
.newClass{
    width: 100%;
    margin-top: 10px;
}
.b-item-plant{
    overflow-y: auto;
    height: 200px;
    margin-top: 10px;
}
.group-list{
    display:flex;
    justify-content: space-between;
    margin-top:5px;
    align-items: center;
}
</style>
