var orderStatusMap = {
    0:"待付款",
    10:"对接中",
    20:"待确认需求",
    30:"制图中",
    40:"待确认效果图",
    50:"已完成",
    60:"修改需求确认",
    70:"改图制图中",
    100:"已关闭",
    41:"待确认效果图",
}
var refundStatusMap = {
    0:"未退款",
    1:"退款中",
    10:"部分退款",
    20:"全部退款"
}
var payChannelCode = {
    "wx_app":"微信",
    "alipay_app":"支付宝"
}
export {orderStatusMap,refundStatusMap,payChannelCode}